var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.componentclass},[_c('div',{class:`${_vm.componentclass}__top`},[(
        _vm.isUserHasPermission(_vm.UserPermissions.VIEW_COMPANY_PROJECTS) &&
        !_vm.isUserCompanyHasParent()
      )?_c('SidebarMenuItem',{attrs:{"icon":_vm.projectsIcon,"title":_vm.$t('navigation.items.projects'),"to":"/projects"}}):_vm._e(),(_vm.isUserHasPermission(_vm.UserPermissions.VIEW_COMPANY_REPORTS))?_c('SidebarMenuItem',{attrs:{"icon":_vm.reportsIcon,"title":_vm.$t('navigation.items.reports'),"to":"/reports"}}):_vm._e(),(_vm.isUserHasPermission(_vm.UserPermissions.VIEW_COMPANY_REPORTS))?_c('SidebarMenuItem',{attrs:{"icon":_vm.reports2Icon,"title":_vm.$t('navigation.items.reports2'),"to":"/reports2"}}):_vm._e(),(_vm.isUserHasPermission(_vm.UserPermissions.VIEW_COMPANY_ENTITIES))?_c('SidebarMenuItem',{attrs:{"icon":_vm.callsIcon,"title":_vm.$t('navigation.items.calls'),"to":"/calls"}}):_vm._e(),(
        _vm.isUserHasPermission(_vm.UserPermissions.VIEW_COMPANY_ENTITIES) &&
        !_vm.isUserCompanyHasParent()
      )?_c('SidebarMenuItem',{staticClass:"cards-sidebar-menu-item",attrs:{"icon":_vm.cardsIcon,"title":`Карточки`,"to":"/cards"}}):_vm._e(),(_vm.isUserHasPermission(_vm.UserPermissions.CREATE_COMPANY_ENTITIES))?_c('SidebarMenuItem',{attrs:{"icon":_vm.callsUploadIcon,"title":`Загрузка звонков`,"to":"/calls-upload"}}):_vm._e(),(
        _vm.isUserHasPermission(_vm.UserPermissions.VIEW_COMPANY_CHECKLISTS) &&
        !_vm.isUserCompanyHasParent()
      )?_c('SidebarMenuItem',{attrs:{"icon":_vm.checkListsIcon,"title":_vm.$t('navigation.items.checkLists'),"to":"/check-lists"}}):_vm._e()],1),_c('div',{class:`${_vm.componentclass}__bottom`},[_c('SidebarMenuActionItem',{attrs:{"icon":_vm.exitIcon,"title":_vm.$t('navigation.items.exit'),"listener":_vm.handleLogout}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }