
import Component from "vue-class-component";
import {
  faTimesCircle,
  IconDefinition,
} from "@fortawesome/free-regular-svg-icons";
import { IUserTutorialComponentProps } from "./IUserTutorialComponentProps";
import * as utils from "@/components/tutorial/UserTutorialUtils";

@Component
export default class UserTutorialComponent extends IUserTutorialComponentProps {
  private componentClass = "UserTutorialComponent";

  private modalCloseIcon: IconDefinition = faTimesCircle;

  get currentRoute() {
    return this.$route.path;
  }

  getOnboardingVideoUrl() {
    return utils.getOnboardingVideoUrl();
  }

  handleModalIsHidden() {
    // console.log('handleModalIsHidden')
    this.closeListener();
  }
}
