
import Component from "vue-class-component";
import { ISidebarMenuItemProps } from "./ISidebarMenuItem";

@Component
export default class SidebarMenuItem extends ISidebarMenuItemProps {
  private componentClass = "SidebarMenuItem";

  getSubmenuIsActive() {
    const pathArr = this.$route.path.split("/");
    const linkArr = this.to.split("/");

    if (this.$route.name == "Reports" && linkArr[1] == "reports") {
      return "active";
    } else {
      return pathArr[1] == linkArr[1] ? "active" : "";
    }
  }
}
