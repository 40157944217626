
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { ListItemModel } from "@/components/ui/types";

@Component({})
export default class InlineItemsList extends Vue {
  @Prop({ type: Array })
  items!: Array<ListItemModel>;

  @Emit()
  itemClicked(item: ListItemModel) {
    return item;
  }
}
