
import { Prop, Vue, Component, Emit } from "vue-property-decorator";
import { uniqueId } from "lodash";
import { ListItemModel } from "@/components/ui/types";

@Component({
  name: "DropdownItemsList",
})
export default class DropdownMultipleSelect extends Vue {
  @Prop({ default: false })
  isLoading!: boolean;

  @Prop({})
  label!: string;

  @Prop({ type: Array })
  items!: Array<ListItemModel>;

  @Emit()
  itemClicked(item: ListItemModel) {
    return item;
  }

  private uniqId = uniqueId("dropdown-items-list-target");
}
