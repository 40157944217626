import Vue, { PropType } from "vue";

export const IUserTutorialComponentProps = Vue.extend({
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    closeListener: {
      required: true,
      type: Function as PropType<() => void>,
    },
  },
});
