
import Vue from "vue";
import Component from "vue-class-component";
import SidebarMenuItem from "./menuItem/SidebarMenuItem.vue";
import SidebarMenuActionItem from "./menuItem/SidebarMenuActionItem.vue";
import {
  IconDefinition,
  faPhone,
  faChartLine,
  faTasks,
  faProjectDiagram,
  faSignOutAlt,
  faAddressCard,
  faChartBar,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { StoreActionTypes } from "@/store/types";
import { isUserCompanyHasParent, isUserHasPermission } from "@/services/roles";
import { UserPermissions } from "@/services/roles/permissionsEnum";

@Component({
  computed: {
    UserPermissions() {
      return UserPermissions;
    },
  },
  methods: { isUserCompanyHasParent, isUserHasPermission },
  components: {
    SidebarMenuItem,
    SidebarMenuActionItem,
  },
})
export default class SidebarNav extends Vue {
  private componentclass = "SidebarNav";

  callsUploadIcon = faUpload;
  private exitIcon: IconDefinition = faSignOutAlt;
  private reportsIcon: IconDefinition = faChartLine;
  private reports2Icon: IconDefinition = faChartBar;
  private projectsIcon: IconDefinition = faProjectDiagram;
  private callsIcon: IconDefinition = faPhone;
  // private criterionsGroupsIcon: IconDefinition = faWindowRestore
  // private criteriaIcon: IconDefinition = faList
  private checkListsIcon: IconDefinition = faTasks;
  cardsIcon: IconDefinition = faAddressCard;

  handleLogout() {
    this.$store.dispatch(StoreActionTypes.LOG_OUT);
    this.$router.push("/login");
  }
}
