import Vue from "vue";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { PropType } from "vue";

export const ISidebarMenuItemProps = Vue.extend({
  props: {
    icon: Object as PropType<IconDefinition>,
    title: String,
    to: String,
  },
});
